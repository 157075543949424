<template>
  <div :style="getCustomStyles()" class="map-wrapper">
    <div :id="mapId" />
  </div>
</template>

<script>
import { Browser, marker, map, icon, markerClusterGroup, tileLayer } from 'leaflet';
import 'leaflet.markercluster';
import { mapBoxProps } from './mapBoxProps';
import { Logger } from '@vue-storefront/core/lib/logger';

export const tileLayerColor = () => {
  return tileLayer(
    `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}${Browser.retina ? '@2x' : ''}?access_token={accessToken}`,
    {
      id: 'mapbox/light-v10',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiZGV2Y2l0eS1taWxhbmt5bmNsIiwiYSI6ImNsMDN2ZnZscjBmenkzZG1qc3EwY3gyMzYifQ._bnCkxxr38KvQkiYX9pj1g'
    });
};

export const createIcon = ({
  url,
  width = 32,
  height = 32,
  anchor: { x = 0, y = 0 } = { x: 0, y: 0 }
}) => {
  return icon({
    iconUrl: url,
    iconSize: [height, width],
    iconAnchor: [x, y]
  });
};

/** @deprecated Do not import this component directly! Use Map.vue component */
export default {
  name: 'MapBox',
  props: mapBoxProps,
  data () {
    return {
      mapId: null,
      map: null,
      mapMarkers: [],
      clusterGroup: null
    }
  },
  mounted () {
    this.mapId = `map-${this._uid}`;
    this.checkConfiguration();
    this.$nextTick(() => {
      if (document.getElementById(this.mapId) !== null) {
        this.initializeMap();
        this.updateMap();
      }
    });
  },
  watch: {
    markers () {
      this.updateMap();
    },
    focusedMarker () {
      if (this.focusedMarker) {
        this.focusMarker(this.focusedMarker);
      }
    }
  },
  methods: {
    getCustomStyles () {
      if (this.size === 'fixed') {
        return {
          width: this.width,
          height: this.height,
          position: 'relative'
        };
      }
      if (this.size === 'fill') {
        return {
          width: '100%',
          height: '100%',
          position: 'absolute'
        };
      }

      return {}
    },
    initializeMap () {
      const mapOptions = {
        attributionControl: false
      };

      this.map = map(this.mapId, mapOptions);
      this.setMapBounds();
      tileLayerColor().addTo(this.map);
    },
    updateMap () {
      this.clearMap();

      if (this.markers.length > 0) {
        this.mapMarkers = this.markers.map((m) =>
          marker([m.lat, m.lng], { icon: createIcon(m.icon) })
            .on('click', this.handleMarkerClick(m))
        );

        if (this.clustering) {
          this.clusterGroup = markerClusterGroup({
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
            animateAddingMarkers: false
          });
          this.mapMarkers.forEach((m) => this.clusterGroup.addLayer(m));
          this.map?.addLayer(this.clusterGroup);
        } else {
          this.mapMarkers.forEach((m) => this.map.addLayer(m));
        }
        this.setMapBounds();
      }
    },
    clearMap () {
      if (this.map) {
        this.mapMarkers.forEach(this.map.removeLayer);
      }
    },
    calculateMapBounds () {
      if (this.markers.length === 0) {
        return [];
      }

      const maxLat = Math.max(...this.markers.map((m) => m.lat));
      const minLat = Math.min(...this.markers.map((m) => m.lat));
      const maxLng = Math.max(...this.markers.map((m) => m.lng));
      const minLng = Math.min(...this.markers.map((m) => m.lng));

      const expandBy = 0.02;
      return [
        [maxLat + expandBy, maxLng + expandBy],
        [minLat - expandBy, minLng - expandBy]
      ];
    },
    setMapBounds () {
      if (this.coordinates) {
        this.map.setView([ this.coordinates.lat, this.coordinates.lng ], this.zoom)
      } else if (this.markers.length > 0) {
        this.map?.fitBounds(this.calculateMapBounds());
      }
    },
    focusMarker () {
      if (this.focusedMarker) {
        this.map.setView([this.focusedMarker.lat, this.focusedMarker.lng], this.zoom);
      }
    },
    handleMarkerClick (marker) {
      return (e) => {
        this.onMarkerClick(marker);
      };
    },
    checkConfiguration () {
      if (this.size === 'fixed' && (!this.width || !this.height)) {
        Logger.warn('You must provide "width" and "height" property when using the fixed size', 'map')();
      }
      if (this.markers.length === 0 && !this.coordinates) {
        Logger.warn('You must provide "coordinates" when no markers are provided', 'map')();
      }
    }
  }
}
</script>

<style src="leaflet/dist/leaflet.css" lang="css"></style>
<style src="leaflet.markercluster/dist/MarkerCluster.css" lang="css"></style>
<style src="leaflet.markercluster/dist/MarkerCluster.Default.css" lang="css"></style>
<style lang="scss" scoped>
.map-wrapper {
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss">
$marker-color-light: #f56951;
$marker-color: #FC3711;

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: rgba($marker-color-light, 0.6);

  div {
    background-color: rgba($marker-color, 0.6);
    color: #fff;
  }
}

.leaflet-oldie {
  .marker-cluster-small,
  .marker-cluster-medium,
  .marker-cluster-large {
    background-color: $marker-color-light;

    div {
      background-color: $marker-color;
      color: #fff;
    }
  }
}
</style>
